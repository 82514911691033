.admin{
     height: 100%;
     width: 100%;
     box-sizing: border-box;
     padding: 0 3.5%;

    .admin-container{ 
        box-sizing: border-box;
        width: 100%;
        height: 29%;

        .app-request{
           color: rgba(0,45,68,0.48);
           font-size: 20px;
           margin-top: 15px;
        }
        .admin-list-container{
            width: 100%;
            display: flex;
           height: 80%;
          
        }
        .admin-card{
            min-width: 31%;
            height: 100%;
             padding: 2%;
             box-sizing: border-box;
             box-shadow: 0.6px 0.6px 10px rgba(0,0,0,0.14);
             border-radius: 8px;
             height: 185px;
             display: flex;
             flex-direction: column;
             justify-content: space-between;
             margin-right: 25px;
        
             p{
                margin: 5px 0;
            }
         
            .admin-date-block{
                display: flex;
                justify-content: space-between;
                align-items: center;
              
              
                .just-send{
                    color: #1D5AA7;
                    font-size: 18px;
                    font-family: publicaMedium;
                    margin-top: 0 !important;
                }
                .just-food{
                    color: #F77125;
                    font-size: 18px;
                    font-family: publicaMedium;
                    margin-top: 0 !important;
                }
                .just-buy{
                    font-size: 18px;
                    font-family: publicaMedium;
                    color: #449F27;
                }
                
                .admin-date{
                    color: #002D44;
                    font-size: 14px;
                    margin-top: 0 !important;
                }
               
            }
            .admin-info{
                display: flex;
                align-items: center;
                margin: 8px 0;
                
                .admin-contact{
                      padding-left: 12px;
                      font-size: 14px;
                      color: #909496;
                }
                .admin-name{
                    margin: 0;
                    color: #002D44;
                   font-size: 16px;
                }
               
            }

            .admin-address{
                font-size: 14px;
                color: #909496;
                width: 60%;
                margin-top: 8px;
            }
        }
    }
}

@media screen and (max-width:1000px){

    .admin-card{
        padding: 5px !important;
        margin-right: 10px !important;
        height: 134px !important;
    }
    
    .just-send{
        font-size: 12px !important;  
    }
    .just-food{
        font-size: 12px !important;
    }
    .just-buy{
        font-size: 12px !important;
    }
    .admin-date{    
        font-size: 11px !important;
    }
   

.admin-info{

    .admin-contact{
          padding-left: 5px !important;
          font-size: 14px; 
      }
    .admin-name{
       font-size: 16px;
    }
   
     }

   .admin-address{
    width: 100% !important;
    margin-top: 4px !important;
    }
}

@media screen and (max-width:700px){
    .admin-list-container{
        flex-wrap: wrap;
    }
} 