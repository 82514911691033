@media screen and (width:768px  ) and (height:1024px) {
    .services{
        width: 77.2% !important;
        box-sizing: border-box !important;
        margin: 2.5% 0 0 10.5%;
        height: 350px !important;
    }

    .service-card{
        min-width: 27% !important;
        height: 90%;
        border-radius: 10px !important;
    }

        .color-box{
            border-radius: 6px !important;
        }
        .service-type{
            color: #002D44;
            font-size: 15px !important;
            margin: 0;
        }
        .service-details{
           font-size: 12px !important;
           margin: 0;
        }
        .more{
            font-size: 12px !important;
        }
        .service-list{
            width: 55% !important;
            font-size: 32px !important;
        }
}

.services{
    width: 77.2%;
    box-sizing: border-box;
    margin: 0 0 0 10.5%;
    height: 477px;
    .service-title{
        padding: 0 15px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        box-sizing: border-box;

    .service-list{
        color: #002D44;
        font-size: 48px;
        line-height: 52px;
        font-family: publicabold;
        width: 45%;
        margin-top: 0;
    }

    .all-services{
       display: flex;
       width: 180px;
       align-items: center;
       justify-content: space-between;
       .l{
            border: 1px solid #03A8EE;
            width: 50px;
            margin-bottom: 30px;
       }

    .all{
        color: #03A8EE;
        font-size: 20px;
        line-height: 24px;
        font-family: PublicaMedium;
        margin-bottom: 48px;
    }
  }

}
    .service-container{
        display: flex;
        overflow: hidden;
        padding-left: 1%;
        width: 115.9%;
        height: 74%;
        box-sizing: border-box;
    }



.service-card{
    min-width: 20%;
    height: 86%;
    border-radius: 14px;
    background-color: #F8F8F8;
    padding: 1%;
    box-sizing: border-box;
    margin: 0 0.7%;
    .color-box{
        width: 20%;
        height: 16%;
        background-color: #0381EE;
        border-radius: 12px;
        box-sizing: border-box;
    }

    .service-type{
        color: #002D44;
        font-size: 22px;
        line-height: 28px !important;
        font-family: publicaBold;
    }

    .service-details{
        color: #909496;
        font-size: 16px;
        line-height: 20px !important;
    }
    .more{
        color: rgb(24, 27, 29);
        font-size: 16px;
        line-height: 24px;
        font-family: publicaMedium;
        float: right;
        margin-top: 5px !important;
    }
}

.service-card:hover{
    background-color: #FFFFFF;
    box-shadow: 2px 14px 25px rgba(0,0,0,0.09);
}
}