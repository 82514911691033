.card{
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    margin: 0 11%;
    margin-bottom: 5%;

   .card-section{
       display: flex;
       flex-direction: column;
       justify-content: center;
       box-sizing: border-box;
    hr{
        border: 2px solid #03A8EE ;
        width: 25px;
        border-radius: 2px;
        margin:0 347px 20px 0;
    }
    summary{
        color: #002D44;
        font-size: 34px;
        font-family: publicaBold;
        line-height: 42px;
    }
    .about{
    color: #909496;
    font-size: 16px;
    line-height: 26px;
    width: 553px;
    margin-bottom: 30px;
    }
}
}

@media screen and (width:768px){
    .card-img{
        width: 200px !important;
        height: 200px !important;
        margin-left: 0 !important;
    }
    summary{
        font-size: 20px !important;
        line-height: 22px !important;
    }
    .about{
     color: #909496;
     font-size: 14px !important;
     line-height: 18px !important;
     width: 370px !important;
     margin-bottom: 25px !important;
 }
    .card-section{
        margin-left: 11%;
        height: 246px !important;
    }
   .card{
       width: 632px !important;
   }
   .buttonWidth {
    width: 153;
   }

}