.manual-documents-details{
    box-sizing: border-box;
    box-shadow: 0.6px 0.6px 10px rgba(0,0,0,0.14);
    border-radius: 14px;
    height: auto;
    margin: 3.5%;
.p-form{
    padding:0% 38%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .details-label{
        color: #002D44;
        font-size: 30px;
        font-family: publicaMedium;
        margin-top: 0;
        margin-bottom: 13px;
        min-width: 855px;
    }
    .details-title{
        font-size: 18px;
        color: #909496;
    }
.drag-drop-section{

                 min-width: 652px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 box-sizing: border-box;
                 margin-bottom: 13px;
                 .passport{
                    width:124px;
                    height:124px;
                    background-color: '#F1F1F1';
                 }
                  .drag-drop-container-passport{
                         margin-left: 24px;
                         background-color: #F8F8F8;
                         width: 715px;
                         height: 124px;
                         padding: 0 0 13px 22px;
                         border-radius: 8px;
                         box-sizing: border-box;
                         .drag-drop-block-passport{
                            display: flex;
                            .drag-drop{
                                width: 605px;
                                height: 58px;
                                display: flex;
                                flex-direction: column;
                                margin-right: 14px;
                                justify-content: center;
                                border-radius: 8px;
                                padding-left: 10px;
                                box-sizing: border-box;
                                border: 1px dashed #979797;
                                .folder{
                                    display: flex;
                                    align-items: center;
                                }
                                .file-name{
                                    margin: 0;
                                    font-size: 16px;
                                    color: #002D44;
                                }
                                .file-type-details{
                                    display: flex;
                                    font-size: 14px;
                                    color: #909496 !important;
                                }
                                .format{
                                    margin: 0;
                                    font-size: 14px;
                                    color: #909496;
                                }
                            }
                            :focus{
                                outline: #F8F8F8;
                            }
                        }
                    }
}

.drag-drop-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #F8F8F8;
    width: 855px;
    min-height: 124px;
    padding-left:  22px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 14px;

    .file-name{
        margin: 0;
        font-size: 16px;
        color: #002D44;
    }
    .file-type-details{
        display: flex;
        font-size: 14px;
        color: #909496 !important;
    }
    .format{
        margin: 0;
        font-size: 14px;
        color: #909496;
    }
    .uploded-file{
        width: 479px;
        height: 33px;
        padding-bottom:10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .file-details{
            display: flex;
            justify-content: center;
            align-items: center;
        }
     }
    .drag-drop-block{
       display: flex;
       margin-bottom: 12px;
      box-sizing: border-box;
       .drag-drop{
            width: 750px;
            height: 58px;
            display: flex;
            flex-direction: column;
            margin-right: 14px;
            justify-content: center;
            border-radius: 8px;
            padding-left: 10px;
            box-sizing: border-box;
            border: 1px dashed #979797;
           .folder{
            display: flex;
            align-items: center;
        }
       }
       :focus{
           outline: #F8F8F8;
       }
   }
}

}
}


.save-back-button{
    float: right;
    margin-top: 2%;
    margin-right: 2%;
.next{
    border-radius: 11px;
    background-color: #03A8EE;
    width: 104px;
    height: 46px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-family: publicaMedium;
    cursor: pointer;
    margin-left: 15px;
}
.next:focus{
    outline: none;
}

.back{
    border-radius: 11px;
    background-color: #ffffff;
    width: 104px;
    height: 46px;
    border: none;
    color: #002D44;
    font-size: 16px;
    font-family: publicaMedium;
    border: 1px solid #002D44;
    cursor: pointer;
}

.back:focus{
   outline: none;
}
}