.notifications{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0 3.5%;
}
.notification-title {
    color: #646464;
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 35px;
  }
  .notificationclearall{
    // height: 33px;
    color: #03A8EE;
    font-size: 18px;
    float: right;
    margin-top: -50px;
    cursor: pointer;
  }
  .notification-unread {
    box-sizing: border-box;
    height: 133px;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    margin-top: 15px;
    align-items: center;
    padding: 30px;
    box-shadow: 0.3px 0.3px 11px rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
  .notification-inline{
    width: 100%;
    display: flex;
    align-items: center;
}
  .notification-read{
    background-color: #1ca7e21a !important;
    box-sizing: border-box;
    height: 133px;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 10px;
    display: flex;
    margin-top: 15px;
    align-items: center;
    padding: 30px;
    box-shadow: 0.3px 0.3px 11px rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
  .notification-message {
    height: 23px;
    width: 100%;
    color: #646464;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    margin-left: 35px;
  }
  .notification-time {
    height: 20px;
    width: 90px;
    color: #ADADAD;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    float: right;
  }
  .notification-profile{
    height: 99.6px;
    width: 100.39px;
    border-radius: 50px;
  }
 

  .animate-delete {
    position: relative;
    -webkit-animation-name: mymove;
    -webkit-animation-duration: 2.5s;
    animation-name: mymove;
    animation-duration: 2.5s;
    visibility: hidden;
     opacity: 0;
    transition: visibility 0s 1.5s, opacity 1.5s linear;
    // animation-timing-function: ease-out;
  }

  @-webkit-keyframes mymove {
    from {left: 0px;opacity: 1;}
    to {left: 100%;opacity: 0;}
  }

  @keyframes mymove {
    0% {left: 0px;opacity: 1;}
    to {left: 100%;opacity: 0;}
  }

  .animate-top {
    position: relative;
    -webkit-animation-name: mymovetop;
    -webkit-animation-duration: 4s;
    animation-name: mymovetop;
    animation-duration: 4s;
    // animation: mymove 3s;
    animation-fill-mode: forwards;
    visibility: visible;
    opacity: 1;
    transition: opacity 4s linear;
  }

  @-webkit-keyframes mymovetop {
 0% {
  opacity: 0;
  transform: translateY(-25px);
}
100% {
  opacity: 1;
  transform: translateY(0px);
}
  }

  @keyframes mymovetop {
0% {
  opacity: 0;
  transform: translateY(-25px);
}
100% {
  opacity: 1;
  transform: translateY(0px);
}
  }

  .refresh {
    color: #03A8EE;
    font-size: 22px;
    float: right;
    margin-top: -50px;
    cursor: pointer;
    margin-right: 178px;
    visibility: visible;
    transition: all 0.3s ease-in-out 0s;

}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.refreshrotate{
  color: #03A8EE;
  font-size: 22px;
  float: right;
  margin-top: -50px;
  cursor: pointer;
  margin-right: 178px;
  visibility: visible;
  -webkit-animation: rotating 0.6s linear infinite;
  -moz-animation: rotating 0.6s linear infinite;
  -ms-animation: rotating 0.6s linear infinite;
  -o-animation: rotating 0.6s linear infinite;
  animation: rotating 0.6s linear infinite;
  transition: all 0.2s infinite ease-in-out 0.2s;
}

.myBtn{
  display: "none";
  cursor:"pointer";
}