.driver-banner{
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 10%;
    border-bottom: 1.73px solid #3F3D56;

   .banner-section{
        width: 66%;
       display: flex;
       flex-direction: column;
       height: 346px;
       justify-content: flex-end;
       padding: 0 0 47px 0;
       box-sizing: border-box;
       margin-right: 8%;

    summary{
        color: #002D44;
        font-size: 50px;
        font-family: publicaBold;
        line-height: 55px;
        margin-bottom: 12px;
    }
    .about{
    color: #909496;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 49px;
    font-family: publicaLight;
    width: 357px;
    }
 }
}
.buttonWidth {
    width: 153;
}
@media screen and (width:768px){
.banner-section{
    width: 66%;
    display: flex;
    flex-direction: column;
    height: 200px !important;
    justify-content: flex-end;
    padding: 0 0 20px 0 !important;
    box-sizing: border-box;

    summary{
        color: #002D44;
        font-size: 25px !important;
        font-family: publicaBold;
        line-height: 30px !important;
        margin-bottom: 5px !important;
    }

    .about{
    color: #909496;
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 20px !important;
    font-family: publicaLight;
    width: 250px !important;
    }
}
     .banner-img{
         width: 350px !important;
         height: 200px !important;
     }
     .driver-banner{
         height:  221px !important;
     }
}