.terms{
    font-size: 18px;
    color: #909496;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;

    .terms-heading{
        text-align: center;
        width: 70%;

        .terms-title{
            font-size: 30px;
            color: #002D44;
            font-family: publicaMedium;
        }

    }

    .clause{
        font-size: 22px;
        font-family: publicaMedium;
        color: #002D44;
    }

    .clause-1 {
        width: 80%;
    }
    .clause-2{
        width: 80%;
        margin-bottom: 40px;
        border-bottom: 1px solid #F1F1F1;
        padding-bottom: 15px;
        box-sizing: border-box;
    }

    .accepts{
        width: 80%;
        margin-bottom: 50px;
        font-size: 18px !important;
        color: #002D44;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .s{
            margin-left: 15px;
            font-size: 18px ;
            color: #002D44;
            margin-top: 0px;
            cursor: pointer;
        }
    }

    .accept{
        width: 80%;
        margin-bottom: 50px;
        font-size: 18px !important;
        color: #002D44;
        display: flex;
        align-items: center;

        .s{
            margin-left: 15px;
            font-size: 18px ;
            color: #002D44;
            margin-top: 0px;
            cursor: pointer;
        }
    }
}