.main{
    padding: 9px 0px 0px 45px;
    display: flex;
    // margin-bottom: -108px;
}
.detailsform{
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
    margin-right: 15px;
    margin-top: 6px;
    .form{

        width: 100%;
        box-sizing: border-box;

    .details-label{
        color: #002D44;
        font-size: 30px;
        font-family: publicaMedium;
        margin-top: 0;
        margin-bottom: 13px;
    }
    .details-title{
        font-size: 18px;
        color: #909496;
    }

.input-field{
    box-sizing: border-box;
    height: 110px;
    width: 305px;
    padding: 10%;
    display: flex;
    flex-direction: column;
    font-family: publicaSans !important;
    margin: 7px; 
    border-radius: 8px;

    .error{
        margin-top: 4px;
        color: #FF4D4D;
        font-size: 14px;
    }

    .input-label{
        display: block;
        color: #002D44;
        font-size: 16px;
        height: 20px;
        box-sizing: border-box;

        .eg{
            font-size: 14px;
            color: #002D44;
            font-family: publicalight;
            padding-left: 4%;
        }
    }


    select{
        padding: 2px 0;
        width: 337px;
        border: none;
        border-bottom: 1px solid #979797;
        // background-color: #F8F8F8 !important;
        font-family: publicaSans;
        font-size: 16px;
        box-sizing: border-box;
        margin-top: 20px;
        color: #909496;
        outline: none;
    }

    select.line{
        border-bottom: 1px solid #FF4D4D;
    }
        .input[type="text" i] {
            padding: 0px 0;
            width: 250px;
            border: none;
            border-bottom: 1px solid #979797;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }
        .line[type="text" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .line[type="tel" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .err{
            color:#FF4D4D ;
            font-size: 13px;
            font-family: publicaLight;
        }
        input[type="tel" i] {
            padding: 0px 0;
            width: 253px;
            border: none;
            border-bottom: 1px solid #979797;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }

    ::placeholder{
        font-size: 16px;
        color: #909496;
        font-family: publicaSans;
        // background-color: #F8F8F8;
    }
    .input[type="text" i]:focus, input[type="tel" i]:focus{
        outline: #F8F8F8;
        border-bottom: 2px solid #03A8EE !important;
    }
}
    .field-line{
border: 1px solid red;
}

}
}

.file-name{
    margin: 0;
    font-size: 16px;
    color: #002D44;
}
.file-type-details{
    display: flex;
    font-size: 14px;
    color: #909496 !important;
}
.format{
    margin: 1;
    font-size: 14px;
    color: #909496;
}
.uploded-file{
    width: 405px;
    height: 33px;
    padding-bottom:10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    .file-details{
        display: flex;
        justify-content: center;
        align-items: center;
    }
 }

.p-details{
    width: 100%;
    box-sizing: border-box;
    margin-left: 50px;
    margin-top: 21px;
.p-form{
    padding:6% 26%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    .details-label{
        color: #002D44;
        font-size: 30px;
        font-family: publicaMedium;
        margin-top: 0;
        margin-bottom: 13px;
        min-width: 652px;
    }
    .details-title{
        font-size: 18px;
        color: #909496;
        min-width: 652px;
    }
}
}

.drag-drop-section{

    min-width: 652px;
    // display: flex;
    align-items: center;
    // justify-content: center;
    box-sizing: border-box;
    margin-bottom: 13px;
    .passport{
       width:104px;
       height:79px;
       background-color: '#F1F1F1';
    }
     .drag-drop-container-passport{
        // margin-left: -8px;
        /* background-color: #F8F8F8; */
        width: 504px;
        height: 79px;
        padding: 0 18px 1px 0;
        border-radius: 8px;
        box-sizing: border-box;
            .drag-drop-block-passport{
               display: flex;
               .drag-drop{
                width: 304px;
                margin-left: 5px;
                height: 76px;
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                justify-content: center;
                border-radius: 8px;
                padding-left: 10px;
                box-sizing: border-box;
                border: 1px dashed #979797;
                   .folder{
                       display: flex;
                       align-items: center;
                   }
                   .file-name{
                       margin: 0;
                       font-size: 16px;
                       color: #002D44 !important;
                   }
                   .file-type-details{
                    display: flex;
                    font-size: 14px;
                    color: #909496 !important;
                }
                   .format{
                       margin: 0;
                       font-size: 12px;
                       color: #909496 !important;
                   }
               }
               :focus{
                   outline: #F8F8F8;
               }
           }
       }
}
.drag-drop-block{
    display: flex;
   box-sizing: border-box;
    .drag-drop{
         width: 411px;
         height: 75px;
         display: flex;
         flex-direction: column;
         margin-right: 9px;
         justify-content: center;
         border-radius: 8px;
         padding-left: 10px;
         box-sizing: border-box;
         border: 1px dashed #979797;
        .folder{
         display: flex;
         align-items: center;
     }
    }
    :focus{
        outline: #F8F8F8;
    }
}

.drag-drop-section{

    min-width: 652px;
    // display: flex;
    align-items: center;
    // justify-content: center;
    box-sizing: border-box;
    margin-bottom: 13px;
    .passport{
       width:104px;
       height:79px;
       background-color: '#F1F1F1';
    }
     .drag-drop-container-passport{
        // margin-left: 24px;
        /* background-color: #F8F8F8; */
        width: 504px;
        height: 79px;
        padding: 0 18px 1px 0;
        border-radius: 8px;
        box-sizing: border-box;
            .drag-drop-block-passport{
               display: flex;
               .drag-drop{
                width: 304px;
                margin-left: 5px;
                height: 76px;
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                justify-content: center;
                border-radius: 8px;
                padding-left: 10px;
                box-sizing: border-box;
                border: 1px dashed #979797;
                   .folder{
                       display: flex;
                       align-items: center;
                   }
                   .file-name{
                       margin: 0;
                       font-size: 16px;
                       color: #002D44 !important;
                   }
                   .file-type-details{
                    display: flex;
                    font-size: 14px;
                    color: #909496 !important;
                }
                   .format{
                       margin: 0;
                       font-size: 12px;
                       color: #909496 !important;
                   }
               }
               :focus{
                   outline: #F8F8F8;
               }
           }
       }
}

.savebutton {
    border: 0px solid;
    margin-top: 81px;
    margin-bottom: 11px;
    width: 350px;
    position: absolute;
    margin-left: 14vw;
}

.editDriverlabel{
    font-size: 23px;
    color: black;
    font-weight: bold;
    width: 145px;
    margin-left: 35px;
    margin-bottom: -16px;
}
.file-name{
    margin: 0;
    font-size: 16px;
    color: #002D44 !important;
}
.file-type-details{
    display: flex;
    font-size: 14px;
    color: #909496 !important;
}
.format{
    margin: 0;
    font-size: 12px;
    color: #909496 !important;
}
.loaderedit{
    text-align: center;
    margin-top: 25%;
    color: #03A8EE;
}


@media screen and (max-width:1024px){
 
    .EditModal {
        position: absolute;
        top: 53%;
        left: 50%;
        display: flex;
        box-shadow: 0.3px 0.3px 11px rgba(0, 0, 0, 0.14);
        align-self: flex-end;
        width: 64%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        outline: transparent;
        box-sizing: border-box;
        justify-content: center;
        min-height: 52vw;
        height: auto;
        border-radius: 12px;
        z-index: 100;
    }

    .detailsform{
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
        margin-right: 15px;
        margin-top: 35px;
    }
    .detailsform .form .input-field .input[type="text" i] {
        padding: 0px 0;
        width: 234px;
        border: none;
        border-bottom: 1px solid #979797;
        font-family: publicaSans;
        font-size: 17px;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .p-details {
        width: 100%;
        box-sizing: border-box;
        margin-left: 26px;
        margin-top: 19px;
    }

    .drag-drop-section .drag-drop-container-passport .drag-drop-block-passport .drag-drop {
        width: 81%;
        margin-left: 5px;
        height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px;
        box-sizing: border-box;
        border: 1px dashed #979797;
    }

    .drag-drop-section .passport {
        width: 67px;
        height: 61px;
        background-color: '#F1F1F1';
    }

    .drag-drop-section .drag-drop-container-passport .drag-drop-block-passport .drag-drop .file-name {
        /* margin: 0; */
        font-size: 11px;
        color: #002D44 !important;}
    .drag-drop-section .drag-drop-container-passport .drag-drop-block-passport .drag-drop .format {
        margin: 0;
        font-size: 12px;
        color: #909496 !important;
    }

    .smallsizep {
        color: rgb(0, 45, 68);
        font-size: 16px !important;
        margin-bottom: 10px;
    }
    
    .drag-drop-block .drag-drop {
        width: 277px;
        height: 71px;
        display: flex;
        flex-direction: column;
        margin-right: 9px;
        justify-content: center;
        border-radius: 8px;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px dashed #979797;
    }

    .detailsform .form .input-field {
        box-sizing: border-box;
        height: 110px;
        width: 224px;
        padding: 0%;
        display: flex;
        flex-direction: column;
        font-family: publicaSans !important;
        margin: 0px;
        border-radius: 8px;
    }

    .uploded-file {
        width: 236px;
        height: 33px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 15px;
    }
  }