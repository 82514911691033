.list-container{
    width: 100%;
    border-spacing: 0px !important;

    .table-head{
        vertical-align: bottom !important;
    }

    .head-row{
       height:  60px;
       color: rgba(0,45,68,0.48);

       th{
         text-align: left;
         padding-bottom: 1%;
       }
    }

  .body-row:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.16);
    background-color: rgba(3,168,238,0.14);
    cursor: pointer;
  }
    .body-row{
        td{
            height: 73px;
            border-bottom:1px solid #E3E3E3 ;
            font-size: 16px;
            color: #002D44;
        }
        .col-1{
            display: flex;
            align-items: center;

            .partner-img{
                height: 35px;
                width: 35px;
                margin: 0 6%;
                display: none;
            }
            .shimmer-img{
              height: 35px;
              width: 35px;
              margin: 0 6%;
              display: block !important;
          }
        }
        .request-name{
            margin:  0;
          }
          .request-address{
           margin:  0;
           font-size: 14px;
           line-height: 19px;
           color: #909496;
         }
    }
}
.partner-img{
  height: 35px;
  width: 35px;
  margin: 0 6%;
}

.payment-img{
  height: 20px;
    width: 20px;
    margin: 0 3%;
    display: block !important;
}

.name-crop{
  display: block;
    overflow: hidden;
    width: 120px;
    text-overflow: ellipsis;
}