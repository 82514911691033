.details_divider {
    width: 1px;
    height: 100px;
    background-color: #7f7f7f54;
    margin-bottom: 7px;
    display: block;
}
.packages {
    height: inherit;
    margin-bottom:18px;
}
.pricedetails {
    display: flex;
    place-content: space-between;
    align-items: center;
}
.cropshort {
    width: 14em;
    /* overflow: hidden; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    min-height:25px;
    height: auto;
  }
  .dashedline {
    height: 48px;
    border-left: 1px solid lightblue;
    border-left-style: dashed;
}
  