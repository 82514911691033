.DocumentUploadModal {
    display: block;
    position: absolute;
    top: 51%;
    left: 50%;
    align-self: flex-end;
    width: 25vw;
    background-color: #fff;
    transform: translate(-50%, -50%);
    outline: transparent;
    box-sizing: border-box;
    justify-content: center;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
}

@media screen  and (width:1024px) {
.DocumentUploadModal {
    display: block;
    position: absolute;
    top: 51%;
    left: 50%;
    align-self: flex-end;
    width: 35vw;
    background-color: #fff;
    transform: translate(-50%, -50%);
    outline: transparent;
    box-sizing: border-box;
    justify-content: center;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
}
}