.addManagerModal {
  position: absolute;
  top: 43%;
  left: 50%;
  display: flex;
  align-self: flex-end;
  width: 91%;
  color: #f00;
  background-color: #fff;
  transform: translate(-50%, -50%);
  outline: transparent;
  box-sizing: border-box;
  justify-content: center;
  height: 65%;
  border-radius: 12px;
  padding: 3%;

    .add-manager-container{
      width: 100%;
      overflow: auto;

     .add-manager-block{
       display: flex;
       justify-content: space-between;
       color: #002D44;
       font-size: 20px;
       margin-bottom: 2.5%;

       .fa-close{
         cursor: pointer;
       }
     }

     .body-row:hover{
        box-shadow: 0 0 0 #fff;
        background-color: #fff ;
        cursor: auto;
      }
      .table-head{
        display: none;
      }
    }
}
  .Overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 18.6%;
    width: 81.4%;
    height: 800px !important;
    box-sizing: border-box;
    z-index: 100;
  }
