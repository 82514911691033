.gm-style .gm-style-iw-d {
    box-sizing: border-box;
    overflow: auto;
    width: 225px;
}

.map{
    width: '100%';
    height:'100%';
    position: "relative";
    box-shadow: "0.6px 0.6px 10px rgba(0, 0, 0, 0.14)";
    border-radius: "14px";
  }