.adminModal {
    display: block;
    position: absolute;
    top: 51%;
    left: 50vw;
    align-self: flex-end;
    // width: 25vw;
    color: #f00;
    background-color: #fff;
    transform: translate(-50%, -50%);
    outline: transparent;
    box-sizing: border-box;
    justify-content: center;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
}

.admin-header{
    display: flex;
    justify-content: space-between;
    color: black;
    font-size: x-large;
    margin-left: 35px;
    padding-right: 15px;
    margin-top: 20px;
    margin: auto;
}
.admin-title{
    margin: auto;
    margin-top: 35px;
}



.admin-details{
    padding: 2vw 4vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form{

        width: 100%;
        box-sizing: border-box;

    .details-label{
        color: #002D44;
        font-size: 30px;
        font-family: publicaMedium;
        margin-top: 0;
        margin-bottom: 13px;
    }
    .details-title{
        font-size: 18px;
        color: #909496;
    }

.input-field{
    box-sizing: border-box;
    height: 74px;
    padding: 0%;
    display: flex;
    flex-direction: column;
    font-family: publicaSans !important;

    .error{
        margin-top: 4px;
        color: #FF4D4D;
        font-size: 14px;
        margin-bottom: 4px;

    }

    .input-label{
        display: block;
        color: #002D44;
        font-size: 20px;
        height: 40px;
        box-sizing: border-box;

        .eg{
            font-size: 14px;
            color: #002D44;
            font-family: publicalight;
            padding-left: 4%;
        }
    }


    select{
        padding: 2px 0;
        width: 337px;
        border: none;
        border-bottom: 1px solid #979797;
        background-color: #F8F8F8 !important;
        font-family: publicaSans;
        font-size: 16px;
        box-sizing: border-box;
        margin-top: 20px;
        color: #909496;
        outline: none;
    }

    select.line{
        border-bottom: 1px solid #FF4D4D;
    }
        .input[type="text" i] {
            padding: 4px 0;
            // width: 325px;
            border: none;
            border-bottom: 1px solid #979797;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }
        .line[type="text" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .line[type="tel" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .err{
            color:#FF4D4D ;
            font-size: 14px;
            font-family: publicaLight;
        }
        input[type="tel" i] {
            padding: 4px 0;
            // width: 325px;
            border: none;
            border-bottom: 1px solid #979797;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }

    ::placeholder{
        font-size: 18px;
        color: #909496;
        font-family: publicaSans;
    }
    .input[type="text" i]:focus, input[type="tel" i]:focus{
        outline: #F8F8F8;
        border-bottom: 2px solid #03A8EE !important;
    }
}
    .field-line{
border: 1px solid red;
    width: 343px;
    border-radius: 8px;
    padding: 1%;
    margin-top: 14px;
}

.upload{
    border-radius: 11px;
    width: 115px;
    height: 34px;
    color: white !important;
    margin: auto;
    border: none !important;
    color: #002D44;
    padding: 9px;
    font-size: 16px;
    font-family: publicaMedium;
    border: 1px solid #002D44;
    cursor: pointer;
    margin-top: 8px;
    background: #03A8EE !important;
}
.form-button{
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
.next{
    border-radius: 11px;
    background-color: #03A8EE;
    width: 104px;
    height: 46px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-family: publicaMedium;
    cursor: pointer;
}
.next:focus{
    outline: none;
}

.save{
    border-radius: 11px;
    width: 320px;
    height: 35px;
    color: white !important;
    margin: auto;
    border: none !important;
    color: #002D44;
    font-size: 16px;
    font-family: publicaMedium;
    border: 1px solid #002D44;
    cursor: pointer;
    background: #03A8EE;
    outline: none !important;
}

.upload{
    outline: none !important;
    border-radius: 11px;
    width: 100px;
    height: 25px;
    padding: 9px !important;
    color: white !important;
    border: none !important;
    color: #002D44;
    font-size: 16px;
    font-family: publicaMedium;
    border: 1px solid #002D44;
    cursor: pointer;
    background: #03A8EE !important;
}

.back:focus{
   outline: none;
}
}
}
}
@media screen  and (width:768px) {
        .eg{
             font-size: 10px !important;
        }
        .input-label{
            font-size: 14px !important;
        }

        .details-label{
            font-size: 25px !important;
        }
        .details-title{
            font-size: 12px !important;
        }
        .input-field{
            height: 92px !important;
            select.line{
                margin-top: 10px !important;
            }

            .input[type="text" i] {
                margin-top: 10px !important;
            }
        }

        .admin-details .form .input-field .input[type="text" i] {
            padding: 4px 0;
            width: 221px;
            border: none;
            border-bottom: 1px solid #979797;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }

        .admin-details .form .input-field input[type="tel" i] {
            padding: 4px 0;
            width: 220px;
            border: none;
            border-bottom: 1px solid #979797;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }
}
@media screen  and (width:1024px) {
    .eg{
         font-size: 10px !important;
    }
    .input-label{
        font-size: 14px !important;
    }

    .details-label{
        font-size: 25px !important;
    }
    .details-title{
        font-size: 12px !important;
    }
    .input-field{
        height: 92px !important;
        select.line{
            margin-top: 10px !important;
        }

        .input[type="text" i] {
            margin-top: 10px !important;
        }
    }

    .admin-details .form .input-field .input[type="text" i] {
        padding: 4px 0;
        width: 221px;
        border: none;
        border-bottom: 1px solid #979797;
        font-family: publicaSans;
        font-size: 16px;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .admin-details .form .input-field input[type="tel" i] {
        padding: 4px 0;
        width: 220px;
        border: none;
        border-bottom: 1px solid #979797;
        font-family: publicaSans;
        font-size: 16px;
        box-sizing: border-box;
        margin-top: 20px;
    }
    .adminModal {
        display: block;
        position: absolute;
        top: 51%;
        left: 50vw;
        align-self: flex-end;
        width: 31vw;
        color: #f00;
        background-color: #fff;
        transform: translate(-50%, -50%);
        outline: transparent;
        box-sizing: border-box;
        justify-content: center;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
    }
}

input[type="file"] {
    display: none;
}
.upload{
    outline: none !important;
    border-radius: 11px;
    width: 100px;
    height: 25px;
    padding: 9px !important;
    color: white !important;
    border: none !important;
    color: #002D44;
    font-size: 16px;
    font-family: publicaMedium;
    border: 1px solid #002D44;
    cursor: pointer;
    background: #03A8EE !important;
}

