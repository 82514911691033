.productImage {
    width: 500px;
    height: 500px;
    margin-left: 10px;
    border-radius: 10px;
}

.productdetails{
    padding:5px 0px 15px 25px
}
.productdetailscontainer {
    background: #f1f1f1;
    width: 500px;
    margin-left: 50px;
    border-radius: 10px;
    padding-bottom: 45px;
    height: 500px;
}
.productdetailsheading{
    color: black;
    font-size: 28px;
    margin-top: 4px;
    padding: 10px 0px 0px 11px;
}
.productdetailsdescription{
    display: flex;
    flex-direction: column;
    padding: 5px 0px 15px 8px;
    background: #f8f8f8;
    border-radius: 12px;
    width: 85%;
    height: 30%;
    margin-left: 25px;
}
.catogery{
    font-weight: "bold";
    color: #39b1e3;
    background: #c9edfd;
    border-radius: 12px;
    height: 25px;
    text-align: center;
    width: 100px;
    padding: 2px 2px 2px 2px;
    font-size: 19px;
    margin-right: 15px;
}
.seller-img{
    height: 100px;
    width: 100px;
    border-radius: 12px;
    margin: 0 2%;
  }

.rating {
    font-size: xx-large;
    color:#39b1e36b ;
}
.checked{
    color: #f5a623 !important;
}
.productData-details-container{
    box-sizing: border-box;
    box-shadow: 0.6px 0.6px 10px rgba(0, 0, 0, 0.14);
    border-radius: 14px;
    height: auto;
    padding: 2%;
    background: white;
}
.productData-details-container2{
    box-sizing: border-box;
    box-shadow: 0.6px 0.6px 10px rgba(0, 0, 0, 0.14);
    border-radius: 14px;
    height: auto;
    padding: 2%;
}