.preview{
    display: flex;
    justify-content: space-between;
    color: black;
    font-size: x-large;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 20px;
}

.image-slider{
    display: block !important;
    margin: auto !important;
    padding: 0px !important;
    border: 0px ;
    overflow: hidden;
    margin-top: 4% !important;
    background: rgb(0, 0, 0) !important;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;}
    .previewModal {
        display: block;
        position: absolute;
        top: 51%;
        left: 50%;
        align-self: flex-end;
        width: 40%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        outline: transparent;
        box-sizing: border-box;
        justify-content: center;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
    }

.customImagepreview{
    text-align-last: center;
    margin-top: 5%;
}
.ImagePreview{
        width: 500px;
        height: 400px;
        border-radius: 18px;
        box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
}
.ImagePreviewContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.trashcustom {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    background: lightgray;
    width: 25px;
    padding: 3px;
    height: 25px;
    border-radius: 12px;
    // float: right;
    color: #f50909c9;
    :hover{
        background: red !important;
    }
}

.trashcustomdiv {
    position: relative;
    right: 10%;
    float: right;
    padding-top: 5%;
}
.nodisplay{
    display: none !important;
}
.PreviewContainer{
   margin-left: 20px;
   margin-right: 20px;
}
.ImagePreviewLoader{
        text-align: center;
        align-content: center;
        margin-top: 20%;
}

@media screen  and (width:1024px) {
    .previewModal {
        display: block;
        position: absolute;
        top: 51%;
        left: 50%;
        align-self: flex-end;
        width: 65%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        outline: transparent;
        box-sizing: border-box;
        justify-content: center;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
    }
}