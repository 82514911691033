.details{
    padding:6% 26%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form{

        width: 100%;
        box-sizing: border-box;

    .details-label{
        color: #002D44;
        font-size: 30px;
        font-family: publicaMedium;
        margin-top: 0;
        margin-bottom: 13px;
    }
    .details-title{
        font-size: 18px;
        color: #909496;
    }

.input-field{
    box-sizing: border-box;
    height: 124px;
    padding:  4%;
    display: flex;
    flex-direction: column;
    font-family: publicaSans !important;
    margin: 5px;
    background-color: #F8F8F8;
    border-radius: 8px;

    .error{
        margin-top: 4px;
        color: #FF4D4D;
        font-size: 14px;
    }

    .input-label{
        display: block;
        color: #002D44;
        font-size: 20px;
        height: 40px;
        box-sizing: border-box;

        .eg{
            font-size: 14px;
            color: #002D44;
            font-family: publicalight;
            padding-left: 4%;
        }
    }


    select{
        padding: 2px 0;
        width: 337px;
        border: none;
        border-bottom: 1px solid #979797;
        background-color: #F8F8F8 !important;
        font-family: publicaSans;
        font-size: 16px;
        box-sizing: border-box;
        margin-top: 20px;
        color: #909496;
        outline: none;
    }

    select.line{
        border-bottom: 1px solid #FF4D4D;
    }
        .input[type="text" i] {
            padding: 2px 0;
            width: 337px; 
            border: none;
            border-bottom: 1px solid #979797;
            background-color: #F8F8F8;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }
        .line[type="text" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .line[type="tel" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .err{
            color:#FF4D4D ;
            font-size: 14px;
            font-family: publicaLight;
        }
        input[type="tel" i] {
            padding: 2px 0;
            width: 337px;
            border: none;
            border-bottom: 1px solid #979797;
            background-color: #F8F8F8;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }

    ::placeholder{
        font-size: 16px;
        color: #909496;
        font-family: publicaSans;
        background-color: #F8F8F8;
    }
    .input[type="text" i]:focus, input[type="tel" i]:focus{
        outline: #F8F8F8;
        border-bottom: 2px solid #03A8EE !important;
    }
}
    .field-line{
border: 1px solid red;
}

.form-button{
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
.next{
    border-radius: 11px;
    background-color: #03A8EE;
    width: 104px;
    height: 46px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-family: publicaMedium;
    cursor: pointer;
}
.next:focus{
    outline: none;
}

.back{
    border-radius: 11px;
    background-color: #ffffff;
    width: 104px;
    height: 46px;
    border: none;
    color: #002D44;
    font-size: 16px;
    font-family: publicaMedium;
    border: 1px solid #002D44;
    cursor: pointer;
}

.back:focus{
   outline: none;
}
}
}
}

.docspadding{
    padding:'44px 78px 44px 124px';
}
@media screen  and (width:768px) {
    .docspadding{
        padding:'0px 78px 44px 0px';
    }
        .eg{
             font-size: 10px !important;
        }
        .input-label{
            font-size: 14px !important;
        }

        .details-label{
            font-size: 25px !important;
        }
        .details-title{
            font-size: 12px !important;
        }
        .input-field{
            height: 92px !important;
            select.line{
                margin-top: 10px !important;
            }

            .input[type="text" i] {
                margin-top: 10px !important;
            }
        }
}



