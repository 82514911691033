.customers{
    box-sizing: border-box;
    height: 369px;
    margin-bottom: 79px;
    z-index: 1;
    .customer-title{
    padding: 0 15px 0 13%;
    height: 29%;
    width: 90%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 2.3%;

    .happy{
        color: #002D44;
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 20px;
        font-family: publicabold;
        width: 414px;
        margin: 0;
    }
    .all{
        color: #03A8EE;
        font-size: 20px;
        line-height: 24px;
        font-family: PublicaMedium;
        display: flex;
        float: right;
        .fa-angle-left, .fa-angle-right{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background-color: #F1F1F1;
            border-radius: 8px;
            color: black;
            font-size: 1.7rem;
            cursor: pointer;
        }
        .activebtn{
            background-color: #03A8EE;
            color: #FFFFFF;
        }
    }

}
    .customer-container{
        display: flex;

        overflow: hidden;
        width: 100%;
    }

.customer-card{
    height: 100%;
    border-radius: 9px;
    border: 1px solid #F1F1F1;
    background-color: #FFFFFF;
    padding: 2% 0 1% 1%;
    box-sizing: border-box;
    margin-right:  1%;
    display: flex;
    .c-about{
        padding-left: 7%;

         .name{
          color: #002D44;
          font-size: 26px;
          line-height: 31px;
           margin-top: 0;
           }

          .customer-details{
           color: #909496;
           font-size: 18px;
           width: 95%;
        }

     }
}

   .customer-card:hover{
    background-color: #F1F1F1;
    box-shadow: 0.2px 0.2px 8px rgba(0,0,0,0.14); 
    }
}

@media screen and (width:768px){
       .customers{
           height: 198px !important;
       }
    .happy{
        color: #002D44;
        font-size: 30px !important;
        line-height: 32px !important;
        margin-bottom: 5px !important;
        width: 245px !important;
    }
         .name{
          color: #002D44;
          font-size: 16px !important;
           line-height: 0 !important;
           margin-top: 0;
           }
          .customer-details{
           font-size: 12px !important;
           }
           .customer-card{
            height: 20% !important;
           }

           .fa-angle-left, .fa-angle-right{
            width: 30px !important;
            height: 30px !important;
            border-radius: 5px !important;
            font-size: 1rem !important;
        }
        .fa-angle-left:hover , .fa-angle-right:hover{
            background-color: #03A8EE;
            color: #FFFFFF;
        }
}