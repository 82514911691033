.faq{
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    .faq-header{
        position: relative;
        padding: 0 7.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        .faq-nav{
            display: flex;
            align-items: center;
            list-style: none;
            width: 63%;
            position: relative;
            justify-content: space-between;
            font-size: 18px;
            color: #002D44;
            height: 9%;
        .header-nav{
            border: 1px solid #03A8EE;
            width: 13vw;
            height: 40px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-family: publicamedium;
            color: #002D44;
             }
        }
    }
    .faq-banner{
        width: 100%;
        height: 300px;
    }
    .faq-list-container{
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        margin-bottom: 58px;
        box-sizing: border-box;
        .faq-title{
            color: #002D44;
            font-size: 40px;
            font-family: publicaBold;
        }
        .faq-t{
        color: #002D44;
        font-size: 18px;
        color: #909496;
    }
    .faq-list{
        .faq-list-block{
            font-size: 18px;
            color: #002D44;
            border-bottom: 1px solid #D7D7D7;
        }
    .faq-list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        font-size: 24px;
        color: #002D44;
        font-family: publicaMedium;
    }
    .faq-drop-button{
        width: 36px;
        height: 36px;
        border-radius: 10px;
        background-color: #D7D7D7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .fdb-active{
        color: #ffffff;
        background-color: #03A8EE;
    }
}

}
@media screen and (min-height:1024px) {
    .faq-nav{
        font-size: 10px !important;
    }
    .header-nav{
        font-size: 10px !important;
        height: 25px !important;
    }
}

}