.Admin-header{
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 1%;
    border-radius: 10px;
    .dashboard-title{
        color: #03A8EE;
        font-size: 26px;
        line-height: 32px;
        font-family: publicaMedium;
        margin-bottom: 0;
    }

    .admin-icon{
        display: flex;
        align-items: center;
        margin-top:45px;
        .notification{
            margin-right: 33px;
            height: 25px;
            width: 25px;
        }
        .profile{
          width: 60px;
          height: 60px;
          border-radius: 10px;
        }
    }
}

.headerback{
  background: white;
  padding: 0%;
  margin-left: -27px;
  margin-right: -67px;
  // margin-top: -28px;
}

.lang_selected{
  font-size: 18px;
  color: #03A8EE;
  margin: 2px;
  margin-right: 2px;
  font-weight: 400;
  cursor: pointer;
}
.lang_notselected{
  font-size: 18px;
  color: #888888;
  margin: 2px;
  margin-right: 2px;
  font-weight: 400;
  cursor: pointer;
}

.lang_divider{
  width: 2px;
  height: 30px;
  background-color:#7F7F7F ;
}
.notificationcount {
  cursor: pointer;
  font-size: 12px;
  color: white;
  font-weight: bold;
  background: orangered;
  width: 22px;
  text-align: center;
  height: 20px;
  justify-content: center;
  border-radius: 13px;
  text-decoration: none;
  margin: -36px 0px 17px 9px;
  padding: 3px;
  position: absolute;
}
.notificationcount0{
  display: none;
}