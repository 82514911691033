.on{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
.header{
    height: 101px;
    border-bottom: 1px solid #979797;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    z-index: 5;

    .just{
        font-size: 30px;
        color: #002D44;
        line-height: 37px;
        font-family: publicaBold;
    }

    .header-nav{
           border: 1px solid #03A8EE;
           width: 187px;
           height: 46px;
           border-radius: 8px;
           display: flex;
           align-items: center;
           justify-content: center;
           font-size: 18px;
           font-family: publicamedium;
           color: #002D44;
    }
}
}
