.RSPBprogressBar {
    width: 250px !important;
    width: 250px !important;
    margin: 5px 0 0 63px;
    height: 2px !important;
    background: repeating-linear-gradient(to right,#c2c2c2 0,#c2c2c2 10px,transparent 0px,transparent 13px);
}
.paradataorder{
    font-size: 14px;
    font-weight: 600;
    color: black;
    margin-bottom: 24px;
}
.paradatadelivered{
    font-size: 14px;
    font-weight: 600;
    color: black;
    margin-bottom: -21px;
}
.pickupfield {
    background: darkgray;
    width: 17px;
    text-align: center;
    height: 17px;
    border-radius: 12px;
    text-decoration: none;
    margin: -17px 0px 0px 11px;
    position: absolute;
}
.orderfield {
    background: darkgray;
    width: 17px;
    text-align: center;
    height: 17px;
    border-radius: 12px;
    text-decoration: none;
    margin: 9px 0px 0px 58px;
    position: absolute;
}
.deliveredfield {
    background: darkgray;
    width: 17px;
    text-align: center;
    height: 17px;
    border-radius: 12px;
    text-decoration: none;
    margin: -14px 0px 0px -21px;
    position: absolute;
}
.achieved{
    background-color: #2d89da !important;
}