.become-driver{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    .become-driver-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    .get-started{
        width: 653px;
        height: 190px;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        box-sizing: border-box;
        .get-started-heading{
            font-size: 30px;
            color: #002D44;
            font-family: publicaMedium;
        }
       .get-started-description{
           font-size: 18px;
           color: #909496;
       }
    }
    .info{
        width: 653px;
        height: 206px;
       background-color: #F1F1F1;
       margin-bottom: 20px;
       border-radius: 12px;
       padding: 15px 22px;
       box-sizing: border-box;
       .info-heading{
           font-size: 28px;
           color: #03A8EE;
           font-family: publicamedium;
           margin: 0;
       }
       p{
           font-size: 16px;
           color: #002D44;
       }
    }
    .disclaimer{
        height: 160px;
        background-color: #F1F1F1;
        margin-bottom: 20px;
        width: 653px;
        border-radius: 12px;
        padding: 18px 22px;
        box-sizing: border-box;
        box-sizing: border-box;
        .d-heading{
             font-size: #002D44 !important;
             font-family: publicaMedium;
             font-size: 20px;
             line-height: 24px;
             margin-top: 0;
        }
        .d-details{
            color: #909496;
            font-size: 14px;
            font-family: publicaLight;
            line-height: 18px;
        }
    }

    .t-c{
    height: 104px;
    background-color: #F1F1F1;
    margin-bottom: 20px;
    width: 653px;
    border-radius: 12px;
    padding: 18px 22px;
    box-sizing: border-box;

    .tc-title{
        color: #002D44;
        font-size: 20px;
        font-family: publicaMedium;
        margin-top: 0;
    }
    }
    .next{
        width: 653px;
        justify-content: flex-end;
        display: flex;
        margin-bottom: 90px;
    }
}
}