.footer{
    background-color: #00131D;
    color: #ffffff;
    height: 322px;
    box-sizing: border-box;
    width: 100%;
    .footer-container{
        display: flex;
        justify-content: space-between;
        padding: 0 100px;
        height: 259px;
        align-items: center;
        box-sizing: border-box;
        .aside{
            margin-bottom: 50px;
            box-sizing: border-box;
            .just{
                font-size: 40px;
                line-height: 49px;
                font-family: publicaMedium;
                margin: 25px 0;
            }
        }
        .list{
            display: flex;
            list-style: none;
            .list-title{
                list-style: none;
                font-family: publicalight;
                color: #f1f1f1;
                margin-left:  130px;
                label{
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 15px;
                    font-family: publicaMedium;
                    display: block;
                }
                li{
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 10px;
                    font-family: publicalight;
                    color: #f1f1f1;
                }
            }
        }
    }

    .footer-line{
        margin: 0 100px;
        .rights{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 17px;

            p{
                margin-left: 5px;
            }
        }
    }
}

@media screen and (width:768px){
    .list-title{
        margin-left:  35px !important;
    }
    .footer-container{
        height: 210px !important;
    }
   .footer{
       height: 274px;
   }
}