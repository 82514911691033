* {
    box-sizing: border-box;
  }
  
  body {
    /* background: #673ab7; */
    /* background: linear-gradient(to right, #512da8, #673ab7); */
  }
  
  .field {
    width: 100%;
    height: 65px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  }
  
  .field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
  
  .field.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }
  .field.visited input {
    background-color: #ffffff!important;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }
  
  .field.active input {
    padding: 24px 16px 8px 16px;
    
  }
  
  .field.active input + label {
    top: 4px;
    opacity: 1;
    color: #C2C2C2;
  }
  
  .field.locked {
    pointer-events: none;
  }
  
  .field input {
    // cursor: pointer;
    width: 100%;
    height: 65px;
    position: relative;
    padding: 0px 13px;
    border: none;
    border-radius: 4px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    // background-color: #F2F2F2!important;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
      0.1s padding ease-in-out;
    -webkit-appearance: none;
  }
  
  .field input::-webkit-input-placeholder {
    /* color: rgba(255, 255, 255, 0.8); */
    color: #C2C2C2;
  }
  .field input::-moz-placeholder {
    /* color: rgba(255, 255, 255, 0.8); */
    color: #C2C2C2;
  }
  .field input:-ms-input-placeholder {
    /* color: rgba(255, 255, 255, 0.8); */
    color: #C2C2C2;
  }
  .field input:-moz-placeholder {
    /* color: rgba(255, 255, 255, 0.8); */
    color: #C2C2C2;
  }
  
  .field input + label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #C2C2C2;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }
  
  .field input + label.error {
    color: #ec392f;
  }
  
  .field p.predicted {
    position: absolute;
    top: 8px;
    left: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #C2C2C2;
    opacity: 1;
    pointer-events: none;
  }
  .errors{
    color: #ec392f!important;
  }
  input:-internal-autofill-selected {
    -webkit-appearance: menulist-button;
    background-color: red !important;
    background-image: none !important;
    color: -internal-light-dark-color(black, white) !important;
}
  