.button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #03A8EE;
    width: 153px;
    border-radius: 11px;
    color: #ffffff;
    font-size: 16px;
    font-family: publicamedium;
    height: 46px;
    box-sizing: border-box;
    line-height: 19px;
    cursor: pointer;
}

@media screen and (width:768px) and (height:1024px){
    .button{
    width: 100px !important;
    height: 35px;
    font-size: 12px !important;
    border-radius: 7px !important;
    }
}