.terms{
    font-size: 18px;
    color: #909496;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;

    .terms-heading{
        text-align: center;
        width: 80%;

        .terms-title{
            font-size: 30px;
            color: #002D44;
            font-family: publicaMedium;
        }

        .terms-titles{
            font-size: 22px;
            color: #002D44;
            font-family: publicaMedium;
        }
    }

    .clause{
        font-size: 22px;
        font-family: publicaMedium;
        color: #002D44;
    }

    .clause-1 {
        width: 80%;
    }
    .clause-2{
        width: 80%;
        margin-bottom: 40px;
        border-bottom: 1px solid #F1F1F1;
        padding-bottom: 15px;
        box-sizing: border-box;
    }

    .accepts{
        width: 80%;
        margin-bottom: 50px;
        font-size: 18px !important;
        color: #002D44;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .s{
            margin-left: 15px;
            font-size: 18px ;
            color: #002D44;
            margin-top: 0px;
            cursor: pointer;
        }
    }

    .accept{
        width: 80%;
        margin-bottom: 50px;
        font-size: 18px !important;
        color: #002D44;
        display: flex;
        align-items: center;
    
        .s{
            margin-left: 15px;
            font-size: 18px ;
            color: #002D44;
            margin-top: 0px;
            cursor: pointer;
        }
    }
}

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 4s;
    animation-name: animatebottom;
    animation-duration: 4s
  }
  @-webkit-keyframes animatebottom {
    from { bottom:-400px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  @keyframes animatebottom { 
    from{ bottom:-400px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }