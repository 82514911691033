.sidebar{
    display: flex;
    height: auto;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFFFFF;
    scroll-behavior: smooth !important;
    .sidebar-block{
      background-color: #FFFFFF;
      width: 18.6%;
      display: flex;
      flex-direction: column;
      z-index: 2;
      padding: 1%;
      box-sizing: border-box;
      height: 100%;
      box-shadow: 7px 0 21px rgba(0,0,0,0.06);
     .user-name{
      font-size: 22px;
      line-height: 37px;
      margin-left: 9%;
      font-family: publicaBold;
      color: #002D44;
      margin-top: 17%;
      }
    .panel{
            margin-top: 30px;
            padding: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            list-style: none;
           .panel-list-title{
               display: flex;
               width: 100%;
               height: 10%;
               align-items: center;
               margin-bottom: 10%;
           .panel-list{
             width: 100%;
             display: flex;
             text-decoration: none;
             height: 100%;
             color: rgba(0,45,68,0.4);
             font-size: 18px;
             line-height: 22px;
             border-radius: 8px;
             box-sizing: border-box;
             align-items: center;
           }
           .panel-icon{
              margin: 0 9%;
            }
           .activetext{
              color: #002D44;
              // background-color: rgba(0,45,68,0.09);
            }

            img{
              padding-top: 6px;
              height: 18px;
              width: 18px;
              margin-right: 30px;
             }
     }
     .activenav{
      background-color:lightgray !important;
      border-radius: 14px !important;
          }
  }
   .logout{
    display: flex;
    width: 100%;
    height: 6.7%;
    align-items: center;
    margin-left: 9%;
    color: red;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    .panel-icon{
      margin-right: 9%;
    }
   }
    .version{
      color: #03A8EE;
      font-size: 16px;
      margin-top: 6vw;
      align-self: center;
    }
  }
}
.sidebardisabled{
  // background:#3c3c3c29 !important;
  position: fixed;
  // pointer-events: none;
  filter: blur(1px);
    -webkit-user-select: none;
    user-select: none;
    // pointer-events: none;
    background-color: rgba(0, 0, 0, 0.2) !important;
}
.panel-right-section{
    display: flex;
    flex-direction: column;
    width: 81.4%;
    box-sizing: border-box;
    height: 100%;
    .panel-right-header{
      width: 100%;
    }
  }
.panel-right-section-child{
    flex-direction: column;
    width: 88%;
    box-sizing: border-box;
    height: auto;
    // min-height: 150%;
    margin: auto;
    margin-top: 0%;
    z-index: 50;
    background: #f8f8f8;
    position: absolute;
  /* border-radius: 11px; */
  margin-left: 13%;
  padding: 0 2%;
  -webkit-animation-name: mydetailschild;
  -webkit-animation-duration: 3s;
  animation-name: mydetailschild;
  animation-duration: 3s;
  box-shadow: -3.4px 0.6px 20px rgba(0, 0, 7, 0.14);
}




@-webkit-keyframes mydetailschild {
  0% {opacity: 1;transform: translateX(1050px);}
  // 50% {left: -100px;opacity: 0.5; background: transparent;}
  // 70% {opacity: 0.7;transform: translateX(-100px)}
  100% {opacity: 1;transform: translateX(0px);}
}

@keyframes mydetailschild {
  0% {opacity: 1;transform: translateX(1050px);}
  // 50% {left: -100px;opacity: 0.5; background: transparent;}
  // 70% {opacity: 0.7;transform: translateX(-100px)}
  100% {opacity: 1;transform: translateX(0px);}
}



.sidebar-animate{
  -webkit-animation-name: sidebarchild;
-webkit-animation-duration: 2s;
animation-name: sidebarchild;
animation-duration: 1s;
animation-delay:2s;
pointer-events: none;
}

@-webkit-keyframes sidebarchild {
0% {opacity: 0;transform: translateX(0px);}
// 50% {left: -100px;opacity: 0.5; background: transparent;}
// 70% {opacity: 0.7;transform: translateX(-100px)}
100% {opacity: 1;transform: translateX(-8px);}
}

@keyframes sidebarchild {
0% {opacity: 0;transform: translateX(0px);}
// 50% {left: -100px;opacity: 0.5; background: transparent;}
// 70% {opacity: 0.7;transform: translateX(-100px)}
100% {opacity: 1;transform: translateX(-8px);}
}

@media screen and (max-width:768px){
  .sidebar{
    height: 100vh !important;
  }
  .panel-list{
     font-size: 10px !important;
     line-height: 12px !important;
 }

 .panel-list-title{
   height: 5.7% !important;
 }

 .panel{
   margin-top: 45px !important;
 }
}

@media screen and (min-height:800px){
    .sidebar{
      height: 100vh;
    }
}

.blurback {
  filter: blur(4px);
  width: 75%;
  margin-left: 18%;
  z-index: 0;
  background-image: url(../../../assets/images/panel/fakedriver.PNG) !important;
}

.driverLottie{
    margin: 15% -15% auto;
    outline: none;
    position: fixed;
    transform: scaleX(-1);
    display:block;
    overflow:hidden;
    animation: driverlottiehide;
    animation-duration: 0.8s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes driverlottiehide {
  0% {opacity: 1; height: 300px; width: 300px;}
  100%{opacity: 0;height: 0px; width: 0px;}
}
  
  @keyframes driverlottiehide {
    0% {opacity: 1; height: 300px; width: 300px;}
    100%{opacity: 0;height: 0px; width: 0px;}
  }