.banner-container{
    position: absolute;
    left: 13.5%;
    top: 48%;
    z-index: 1;
    box-sizing: border-box;
    height: 41%;

    .bn-heading{
        color: #ffffff;
        font-size: 50px;
        margin: 0;
        line-height: 55px;
        width: 69%;
        font-family: publicabold;
    }
    .bn-title{
        font-size: 1.1rem;
        line-height: 22px;
        width:65%;
        font-family: publicalight;
        color: #ffffff;
        margin-top: 25px;

    }
    .bn-more{
        background-color: #03A8EE;
        width: 19%;
        border-radius: 11px;
        color: #ffffff;
        font-size: 1rem;
        line-height: 19px;
        margin: 0;
        font-family: publicamedium;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 17%;
    }
}

@media screen and (width:768px){
    .bn-heading{
        font-size: 20px !important;

        width: 40% !important;
        line-height: 22px !important;
    }

    .bn-title{
        font-size: 10px !important;
        line-height: 14px !important;
        margin-top: 10px !important;
    }

    .bn-more{
        border-radius: 6px !important;
        font-size: 10px !important;
        line-height: 12px !important;
    }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100% !important;
}
.image-gallery-bullets {
    bottom: 13% !important;
    left: 69% !important;
    right: 145px !important;
    width: 30% !important;
}
.image-gallery-bullets .image-gallery-bullet {
    background-color: #ffffff !important;
    padding: 4px !important;
}
.image-gallery-bullets .image-gallery-bullet.active {
    background-color: #03A8EE !important;
    border: 1px solid #03A8EE !important;
}