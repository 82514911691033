.drivers{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 3.5%;

    .drivers-header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .partner-database{
           color: rgba(0,45,68,0.48);
           font-size: 20px;
        }
    }
    .shortlisted{
        box-sizing: border-box;
        width: 100%;
        border-radius: 14px;
        background-color: #FFFFFF;
        box-shadow: 0.3px 0.3px 11px rgba(0,0,0,0.14);
    }

    ul.pagination {
        display: flex;
        // padding: 0;
        // margin: 0;
        list-style: none;
        place-content: center;
        text-decoration: none;
    }

    ul.pagination li {
        color: black;
        background-color: gainsboro;
    }

    ul.pagination li a {
        // border: 1px solid #ddd; /* Gray */
        padding: 15px 26px;
        display: inline-block;
        text-decoration: none;
        color: black;
        // background-color: gainsboro;
    }

    .active{
        background-color: #03A8EE !important;
        color:aliceblue !important;
    }

    .disable{
        background-color: lightgray !important;
        pointer-events: none;
        cursor: none;
    }

    .pagination li:first-child a {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        text-decoration: none;
    }
    
    .pagination li:last-child a {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        text-decoration: none;
    }


}