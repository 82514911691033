.driver-details{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom:15px;
    padding-top:0px;
    padding-left:3.5%;
    padding-right:3.5%;

}
.active-blur{
    filter: blur(20px);
    user-select: none;
    pointer-events: none;
     background-color: rgba(0,0,0,0.2);
}
.EditModal {
  position: absolute;
  top: 53%;
  left: 50%;
  display: flex;
  box-shadow: 0.3px 0.3px 11px rgba(0, 0, 0, 0.14);
  align-self: flex-end;
  width: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  outline: transparent;
  box-sizing: border-box;
  justify-content: center;
  min-height: 37vw;
  height: auto;
  border-radius: 12px;
  z-index: 100;
  }

  .EditOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
     overflow: auto;
    right: 0px;
    /* width: 1152px; */
    z-index: 100;
    bottom: 0px;
    /* border-radius: 53px; */
    background-color: #f1f1f1d9;
}
  