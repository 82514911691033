.navbar{
    background-color: #ffffff;
    height: 15%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0 9.1%;
    align-items: center;
    .nav-title{
        color: #002D44;
        font-size: 30px;
        font-family: publicaBold;
    }
    .nav-item{
        display: flex;
        list-style: none;
        width: 58%;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        li .dropdown-menu{
         display: none;
        }
        li:hover .dropdown-menu{
            z-index: 1;
            display: flex;
            position: absolute;
            flex-direction: column;
            justify-content: space-evenly;
            list-style: none;
            right: 16.5%;
            left: 67%;
            background-color: #ffffff;
            min-height: 154px;
            padding-left: 25px;
            border-radius: 8px;
            box-shadow: 0px 0.3px 14px rgba(0,0,0,0.07);
            width: 12%;
            border: 1px solid #F1F1F1;
            .menu-link{
                display: flex;
                text-decoration: none;
                cursor: pointer;
                color: #909496;
                font-size: 18px;
                font-family: PublicaSans;
            }
            :hover{
                color: #03A8EE;
            }
        }
        .nav-link{
            display: block;
            height: 30px;
            text-decoration: none;
            cursor: pointer;
            color: #002D44;
            font-size: 1.1rem;
            font-family:PublicaSans ;
            .active-border{
                display: none;
            }
        }

        .nav-link:hover{
            color: #03A8EE;
        }
        .link-active{
            color: #03A8EE;
            font-family: publicaBold;
            // border-bottom: 2px solid #03A8EE;
            .active-border{
                display: block;
                width: 55% ;
                border: 1px solid #03A8EE;
            }
        }
    }
}

@media screen and (max-width:768px){
    .navbar{
        height: 10% !important;
    }
}