.welcomecontainer{
  height: 500px;
  width: 650px;
  border-radius: 57px;
  margin: auto;
  background: linear-gradient(231.16deg, #E1F6FF 0%, #FFF0D9 100%);
  top: 50px;
}

.welcometojust {
  width: 470px;
  color: #002D44;
  font-size: 56px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 40px
  }
  .start-your-journey-w {
    height: 28px;
    width: 713px;
    color: #909496;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 28px;
    margin: auto;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 40px;
  }

  .onway{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .socialicon{
      text-align: center;
  }
  .fb {
    height: 42px;
    width: 42px;
    margin-left: 20px;
  }
  .tw {
    height: 42px;
    width: 42px;
  }




  @media screen and (max-width:768px) and (max-width:1024x){

  .welcomecontainer{
    height: 500px;
    width: 100%;
    border-radius: 57px;
    margin: auto;
    background: linear-gradient(231.16deg, #E1F6FF 0%, #FFF0D9 100%);
    top: 50px;
  }
  
  .welcometojust {
    width: 100%;
    color: #002D44;
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 37px;
    text-align: center;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 40px
    }
    .start-your-journey-w {
      height: 28px;
      width: 100%;
      color: #909496;
      font-size: 28px;
      letter-spacing: 0;
      line-height: 28px;
      margin: auto;
      text-align: center;
      padding-top: 8px;
      padding-bottom: 40px;
    }
  }