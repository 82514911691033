.title-list-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    color: rgba(0,45,68,0.4);
    border-radius: 9px;
    margin: 5px 0 16px 0;
    height: 51px;
    box-sizing: border-box;
    padding-right: 1%;
    box-shadow: 0.3px 0.3px 11px rgba(0,0,0,0.14);

    .title-list-block{
        width: 100%;
        display: flex;
        list-style: none;
        justify-content: space-between;
        align-items: center;
        padding-left: 1%;
        padding-top: 10px;
        .short{
            color: #03A8EE;
        }
        li{
            cursor: pointer;
        }
        .line-none{
            margin-bottom: 0;
            border: 3px solid #ffffff;
            width: 134px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .bottom-line{
           font-family: publicamedium;
            border: 3px solid #03A8EE;
        }
    }
}
@media screen and (width:768px) {
    .title-list-header{
        font-size: 18px !important;
        li{
        width: 127px;
        }
    .title-list-block {
       padding-top: 13px !important;
       .search-name{
        font-size: 16px;
        }
        ::placeholder{
            font-size: 14px !important;
        }
       .bottom-line{
        width: 105px !important;
        }
     }
   }
}