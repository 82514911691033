.enquiry-details{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-bottom:15px;
    padding-top:0px;
    padding-left:3.5%;
    padding-right:3.5%;

}
.active-blur{
    filter: blur(20px);
    user-select: none;
    pointer-events: none;
     background-color: rgba(0,0,0,0.2);
}