.process-card{
    width: 336px;
    height: 277px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 31px;
    background-color: #F1F1F1;
    justify-content: flex-end;
    padding: 0 45px;
    margin: 0 7px;

    .process-img{
        position: relative;
       margin-bottom: 30px;
        
    }
    .fill{
       width: 100%;
        font-size: 20px;
        color: #002D44;
        font-family: publicamedium;
        margin: 10px 0;
        text-align: center;
    }

    .info{
        width: 246px;
        font-size: 16px;
        color: #909496;
        text-align: center;
        margin-bottom: 50px;
    }
}


@media screen and (width:768px){
    .process-card{
        width: 230px !important;
        height: 180px !important;
     
        border-radius: 18px !important;
    
        padding: 0 26px !important;
        margin: 0 7px;
    
        .process-img{
            position: relative;
           margin-bottom: 20px;
           padding: 10px !important;
           
            
        }
        .p-img{
        width: 160px !important;
        height: 140px !important;
        }
        .fill{
            width: 100%;
            font-size: 14px !important;
         
            margin: 3px 0 !important;
           
        }
    
        .info{
            width: 100%;
            font-size: 11px !important;
            margin-bottom: 20px !important;
        }
    }
}