// @import '../../styles/common';
// @import '../../styles/utilities';
.flexRow{
    display:flex;
    flex-direction:row;
   
    // @include mdMinMax{
    //     margin-left:50px;
    // }
}
.flexColumn{
    display:'flex';
    flex-direction:'column';
}
.login {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // @extend .margin_top_60;
}
.have_account_text{
    font-size: 16px;
    font-family: fontFamily(poppinsRegular)
}
.login_card_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 806px;
    height: 778px;
    background: #ffffff;
    z-index: 1000;
    margin-left: -30px;
    margin-top: 82px;
    margin-bottom: 82px;
    box-shadow: 0 4px 8px 0 rgba(201,201,201,0.5);
    // @include mdMinMax{
    //     margin-left: -3px;
    //     width:60%;
    // }
    // @include smMed{
    //     margin-top:200px;
    //     margin-left: -60px;
    //     width: 62%;
    // }
}
.login_sub_container{
    width:62%;
    // @include mdMinMax{
       
    //     width:72%;
    // }

    .terms-titles{
        font-size: 18px;
    }
}
input[type="submit" i] {

    width:350px;
    height:70px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 30px;
    border:none;
    background-color:#03A8EE!important;
    user-select: none;
    white-space: pre;
    align-items: flex-start;
    text-align: center;
    cursor: default;
    color: white!important;
    background-color: buttonface;
    padding: 1px 7px 2px;
    font-size: 26px!important;
    cursor: pointer;
    // @include smMed{
    //     width:200px;
    //     height:70px;
    // }
    // @include mdMinMax{
    //     width:200px;
    //     height:70px;
    // }
}
.remember{
    width: 20px;
    height: 20px;
    border: 1px solid #979797;
    border-radius: 10px;
    margin-top: 14px;
}
.no_remember{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-top: 14px;
    background: #03A8EE;

}
.button_center{
    display: flex;
    justify-content: center;
    margin-top:60px;
    margin-bottom: 50px;

}
.details_error{
    color: red;
}