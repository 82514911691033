.home{

width: 100%;
box-sizing: border-box;
height: 100vh;
.core-values{
    display: flex;
    width: 77.5%;
    box-sizing: border-box;
    justify-content: space-between;
    margin: 5.5% 12%;

   .cv-section{
       width: 36%;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       margin-right: 7%;
       box-sizing: border-box;
       hr{
           border: 2px solid #03A8EE ;
           width: 7%;
           border-radius: 2px;
           margin:0 92% 0 0;
       }

       summary{
        color: #002D44;
        font-size: 48px;
        font-family: publicaBold;
        width: 90%;
        box-sizing: border-box;
       }
       .cv-about{
        color: #909496;
        font-size: 1rem;
        line-height: 22px;
        width: 100%;
    }
    @media screen and (width:768px) {
        .cv-about{
            font-size: 0.6rem;
            line-height: 18px !important;
        }
        summary{
            font-size: 28px;
            width: 219px;
        }
    }
   }

   .cv-figure{
       margin: 0;
       margin-left: 7%;
       width: 49.5%;
       display: flex;
       flex-direction: column;
       justify-content:flex-end;
       .cv-figure-title{
           display: flex;
           justify-content: flex-end;
           color: #002D44;
           font-size: 18px;
           margin-bottom: 20px;
           line-height: 22px;
       }
   }
}

.status{
    width: 77.5%;
    display: flex;
    list-style: none;
    margin: 5.5% 12%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0;
    .number{
        color: #03A8EE;
        font-size: 50px;
        margin: 0;
        box-sizing: border-box;
        font-family: publicabold;
    }
    .title{
        color: #002D44;
        font-size: 17px;
    }
    @media screen  and (width:768px) {
        .number{
            color: #03A8EE;
            font-size: 20px;
            margin: 0;
            box-sizing: border-box;
            font-family: publicabold;
        }
        .title{
            color: #002D44;
            font-size: 14px;
        }
    }
}
  .newsletter{
      height: 188px;
      background-color: #03A8EE;
      box-sizing: border-box;
      width: 100%;
      padding: 0 11%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 42px;
      .bold{
          width: 279px;
          color: #ffffff;
          font-size: 51px;
          font-family: publicabold;
          margin:0;
      }
      .summary{
          color: #ffffff;
          font-size: 18px;
          width: 426px;
          margin: 0;

      }
      .join-button{
              width: 178px;
              height: 46px;
              border-radius: 9px;
              background-color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
      }
  }
}

@media screen and (width:768px){
        .bold{
            font-size: 25px !important;
        }
        .summary{
            font-size: 12px !important;
        }
}