.driverdetails{
    padding:3% 22%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form{

        width: 170%;
        box-sizing: border-box;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2) !important;
        padding: 25px !important; 
        border-radius: 15px;


    .details-label{
        color: #002D44;
        font-size: 30px;
        font-family: publicaMedium;
        margin-top: 0;
        margin-bottom: 13px;
    }
    .details-title{
        font-size: 18px;
        color: #909496;
    }
   

.input-field{
    box-sizing: border-box;
    height: 124px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    font-family: publicaSans !important;
    margin: 5px;
    background-color: #F8F8F8;
    border-radius: 8px;

    .error{
        margin-top: 4px;
        color: #FF4D4D;
        font-size: 14px;
    }

    .input-label{
        display: block;
        color: #002D44;
        font-size: 20px;
        height: 40px;
        box-sizing: border-box;

        .eg{
            font-size: 14px;
            color: #002D44;
            font-family: publicalight;
            padding-left: 4%;
        }
    }


    select{
        padding: 2px 0;
        width: 337px;
        border: none;
        border-bottom: 1px solid #979797;
        background-color: #F8F8F8 !important;
        font-family: publicaSans;
        font-size: 16px;
        box-sizing: border-box;
        margin-top: 20px;
        color: #909496;
        outline: none;
    }

    select.line{
        border-bottom: 1px solid #FF4D4D;
    }
        .input[type="text" i] {
            padding: 2px 0;
            width: 337px; 
            border: none;
            border-bottom: 1px solid #979797;
            background-color: #F8F8F8;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }
        .line[type="text" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .line[type="tel" i]{
            border-bottom: 1px solid #FF4D4D !important;
        }
        .err{
            color:#FF4D4D ;
            font-size: 14px;
            font-family: publicaLight;
        }
        input[type="tel" i] {
            padding: 2px 0;
            width: 337px;
            border: none;
            border-bottom: 1px solid #979797;
            background-color: #F8F8F8;
            font-family: publicaSans;
            font-size: 16px;
            box-sizing: border-box;
            margin-top: 20px;
        }

    ::placeholder{
        font-size: 16px;
        color: #909496;
        font-family: publicaSans;
        background-color: #F8F8F8;
    }
    .input[type="text" i]:focus, input[type="tel" i]:focus{
        outline: #F8F8F8;
        border-bottom: 2px solid #03A8EE !important;
    }
}
    .field-line{
border: 1px solid red;
}


}
}
.next-back-button{
    float:right;
.next{
    border-radius: 11px;
    background-color: #03A8EE;
    width: 104px;
    height: 46px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-family: publicaMedium;
    cursor: pointer;
    margin-left:15px;
}
.next:focus{
    outline: none;
}

.back{
    border-radius: 11px;
    background-color: #ffffff;
    width: 104px;
    height: 46px;
    border: none;
    color: #002D44;
    font-size: 16px;
    font-family: publicaMedium;
    border: 1px solid #002D44;
    cursor: pointer;
}

.back:focus{
   outline: none;
}
}
.add-new-driver{
    height: 16%;
    width: 100%;
    box-sizing: border-box;
    padding-left:3.5%;
    padding-right:3.5%;
    padding-top: 0%;
}
.color-grey{
    color:grey;
    font-size:18px;
    padding-left:3.5%;
}
.step2of3{
    float: right;
    padding-right: 3.5%;
    font-size:18px;
}
.inputStyle {
    width: 3rem !important;
    height: 3rem;
    margin: 0 0rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid  #03A8EE;
    color: #03A8EE;
    background-color: #F1F1F1;
  }
.otpcard {
    display: grid;
    position: absolute;
    top: 51%;
    left: 60%;
    align-self: flex-end;
    width: 30%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    outline: transparent;
    box-sizing: border-box;
    justify-content: center;
    height: 32%;
    border-radius: 12px;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.28) !important;
    padding: 1%;
}
.validate{
    margin-left: 18px;
    height: 29px;
    width: 76px;
    margin-top: 23px;
}
@media screen  and (width:768px) {
        .eg{
             font-size: 10px !important;
        }
        .input-label{
            font-size: 14px !important;
        }

        .details-label{
            font-size: 25px !important;
        }
        .details-title{
            font-size: 12px !important;
        }
        .input-field{
            height: 92px !important;
            select.line{
                margin-top: 10px !important;
            }

            .input[type="text" i] {
                margin-top: 10px !important;
            }
        }
}



.details-action-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 10%;
    .partner-request{
        color: rgba(0,45,68,0.48);
        font-size: 20px;
        }
    .action-button{
        display: flex;
    }
}

.column {
    float: left;
    width: 25%;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .flex-container {
    display: flex;
   
  }
  
  .flex-container > div {
    margin: 10px;
    padding: 20px;
    font-size: 30px;
  }
.details-container{
    box-sizing: border-box;
    box-shadow: 0.6px 0.6px 10px rgba(0,0,0,0.14);
    border-radius: 14px;
    height: auto;
    padding: 4%;
    background: white;
    .manager-table{
        width: 100%;
        border-spacing: 0 !important;
    .manager-head{
        th{
            text-align: start;
            font-size: 16px;
            color: #909496;
            padding-bottom: 2%;
            font-weight: normal ;
        }
    }
    .manager-body{
        color: #002D44;
        font-size: 18px;
        .manager-data{
        height: 66px;

@media screen and (max-width:768px){
    .assigned-manager{
        width: 124px !important;
        margin-left: 7px !important;
        .am-name{
            font-size: 18px !important;
        }
        .am-id, .am-number{
            font-size: 14px !important;
        }
    }
    .am-img{
        width: 45px !important;
        height: 45px !important;
    }
}
    .assigned-manager{
    margin-left: 11px;
    .am-name{
        font-size: 20px;
        margin: 0;
    }
    .am-id, .am-number{
        font-size: 16px;
        margin: 0;
        color: #909496;
    }
}
    .am-img{
        height: 58px;
        width: 58px;
    }
    .m-img{
        width: 58px;
        height: 58px;
        margin-right: 4%;
    }
    .m-name{
        font-size: 26px;
        margin: 0;
        padding-bottom: 5px;
    }
}
}
}
.credential{
    font-size: 18px;
    margin-top: 8%;
}

    .uploaded-document{
        width: 100%;
        height: 61%;
        margin-top: 6%;
        box-sizing: border-box;
        .document-table{
            width: 100%;
            border-spacing: 0px !important;
            .document-body{
                width: 100%;
                td{
                    height: 64px;
                    border-bottom:1px solid #f1f1f1;
                    font-size: 14px;
                    color: #909496;
                    vertical-align: bottom;
                    box-sizing: border-box;
                }
                .col-1{
                    display: flex;
                    align-items: center;

                    .checked{
                        height: 34px;
                        width: 34px;
                        margin-right:  3%;
                    }
                }
                .d-format{
                    padding-bottom: 1%;
                }

                .document-type{
                    margin:  0;
                    font-size: 18px;
                    color: #002D44;
                    font-family: publicaMedium;
                  }
                  .d-files{
                   margin:  0;
                   font-size: 16px;
                   line-height: 17px;
                   color: #909496;
                 }
                  .document-action{
                    text-align: end;
                    vertical-align: middle;
                  .eye{
                   display: inline-block;
                   text-align: center;
                     vertical-align: middle;
                     width: 40px;
                     height: 40px;
                     background-color: #F1F1F1;
                     border-radius: 8px;
                     margin-left: 8px;
                  }
                }
            }
        }
    }
}

@media screen and (max-width:768px){
.manager-head{
    th{
        font-size: 12px !important;
    }
}
.manager-body{
    font-size: 14px !important;
    .manager-data{
        .m-name{
            font-size: 20px !important;
        }
        .m-img{
            width: 45px !important;
            height: 45px !important;
        }
    }
   }

   .document-body{
       td{
           vertical-align: middle !important;
       }
   }
}
