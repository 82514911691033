.footer-card{
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 330px;
    box-sizing: border-box;
    justify-content: space-between;
    margin: 0 15% 5% 15%;
    border:2px solid #002D44;
    border-radius:31px;
    padding:1.4% 5% 0 5%;

   .footer-card-section{
       display: flex;
       justify-content: space-between;
       box-sizing: border-box;

       .fc-b{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #03A8EE;
            width: 187px;
            border-radius: 11px;
            color: #ffffff;
            font-size: 16px;
            font-family: publicamedium;
            height: 46px;
            box-sizing: border-box;
            line-height: 19px;
            cursor: pointer;
        }

       summary{
           color: #002D44;
           font-size: 34px;
           font-family: publicaBold;
           line-height: 42px;
        }
       .footer-card-about{
        font-size: 18px;
        line-height: 20px;
        font-family: publicalight;
        color: #002D44;
        margin-bottom: 0;
        padding-right: 70px;
        }
   }

}

@media screen and (width:768px){
    .fc-img{
        height: 120px !important;
    }
    .footer-card{
        height: 245px !important;
    }
    .footer-card-about{
        font-size: 10px !important;
       padding: 0 !important;
    }
    .fc-b{
        font-size: 12px !important;
        height: 34px !important;
       width: 136px !important;
    }
}