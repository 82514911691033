// @import '../../styles/utilities';
.justBox{
    width: 35%;
    height:1024px;
    background-color: rgb(242, 242, 242);
    box-shadow: rgba(201, 201, 201, 0.5) 6px 0 5px -2px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.logo{
    width: 250px;
    height: 94px;
    display:flex;
    align-self: center;
 

}
.flexColumn{
    display:'flex';
    flex-direction:'column';
}
.title_login{
    display:flex;
    align-self: center ;
    color:#868686;
    font-size: 30px;
   
}
.subTitle{
    display: flex;
    /* align-self: center; */
    text-align: center;
    width: 243px;
    color:#939393;
    font-size: 16px;
    line-height: 25px;
  

}
.text_value_center{
    display: flex;
    justify-content: center
}
.just{
    text-align:center;
    font-size:55px;
    color:#03A8EE;
    font-weight: bold;
}