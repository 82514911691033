
.react-confirm-alert-button-group
{
    display: flex;
    justify-content: center !important;
    margin-top: 20px
}
.react-confirm-alert-button-group > button {
    outline: none;
    background: #03A8EE !important;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}
.react-confirm-alert-body h1{
    font-size:20px !important;
}
.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    padding-bottom: 250px !important;
}
.loaderStyle
{
    display: flex;
    justify-content : center;
    height: center;
    flex: 1;
    position: absolute;
    left: 50%;
    top: 50%,
}
.PhoneInput{
display: flex;
}
.PhoneInputCountryIcon{
// width: 20px;
display: none;
}
.PhoneInputCountrySelect{
    width: 91px !important;
    margin-right: 10px;
    margin-top: 22px;
}
.PhoneInputInput{
    margin-bottom: 18px;
    width: 240px !important;
}
.ReactModal__Content .ReactModal__Content--after-open{
    z-index: 100 !important;
}