.panel-right-section {
    display: flex;
    flex-direction: column;
    width: 78.4%;
    box-sizing: border-box;
    height: 100%;
    margin: auto;
    margin-top: 0%;
}

.your-profile {
    height: 20px;
    width: 150px;
    color: rgba(0,45,68,0.48);
    letter-spacing: 0;
    line-height: 24px;
    color: rgba(0, 45, 68, 0.48);
    font-size: 20px;
  }

.admin-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.manager-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.5% !important;
}
.superAdminManagerlist{
    .title-list-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 20px;
        color: rgba(0, 45, 68, 0.4);
        border-radius: 9px;
        margin: 33px 0 0px 0px;
        height: 51px;
        box-sizing: border-box;
        padding-right: 1%;
        box-shadow: none;
    }

}