.details-action-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 10%;
    .partner-request{
        color: rgba(0,45,68,0.48);
        font-size: 20px;
        }
    .action-button{
        display: flex;
    }
}
.column {
    float: left;
    width: 25%;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .flex-container {
    display: flex;
   
  }
  
  .flex-container > div {
    margin: 10px;
    padding: 20px;
    font-size: 30px;
  }
.details-container{
    box-sizing: border-box;
    box-shadow: 0.6px 0.6px 10px rgba(0,0,0,0.14);
    border-radius: 14px;
    height: auto;
    padding: 4%;
    background: white;
    .manager-table{
        width: 100%;
        border-spacing: 0 !important;
    .manager-head{
        th{
            text-align: start;
            font-size: 16px;
            color: #909496;
            padding-bottom: 2%;
            font-weight: normal ;
            width: 33.3%;
        }
    }
    .manager-body{
        color: #002D44;
        font-size: 18px;
        .manager-data{
        height: 66px;

@media screen and (max-width:768px){
    .assigned-manager{
        width: 124px !important;
        margin-left: 7px !important;
        .am-name{
            font-size: 18px !important;
        }
        .am-id, .am-number{
            font-size: 14px !important;
        }
    }
    .am-img{
        width: 45px !important;
        height: 45px !important;
        background: lightgreen;
    }
}
    .assigned-manager{
    margin-left: 11px;
    .am-name{
        font-size: 20px;
        margin: 0;
    }
    .am-id, .am-number{
        font-size: 16px;
        margin: 0;
        color: #909496;
    }
}
    .am-img{
        height: 58px;
        width: 58px;
        background: lightgreen;
    }
    .m-img{
        width: 58px;
        height: 58px;
        margin-right: 4%;
    }
    .d-shimmer-img{
        width: 58px;
        height: 58px;
        margin-right: 4%;
        display: none;
    }
    .m-name{
        font-size: 26px;
        margin: 0;
        padding-bottom: 5px;
    }
}
}
}
.credential{
    font-size: 18px;
    margin-top: 8%;
}

    .uploaded-document{
        width: 100%;
        height: 61%;
        margin-top: 6%;
        box-sizing: border-box;
        .document-table{
            width: 100%;
            border-spacing: 0px !important;
            .document-body{
                width: 100%;
                td{
                    height: 64px;
                    border-bottom:1px solid #f1f1f1;
                    font-size: 14px;
                    color: #909496;
                    vertical-align: bottom;
                    box-sizing: border-box;
                }
                .col-1{
                    display: flex;
                    align-items: center;

                    .checked{
                        height: 34px;
                        width: 34px;
                        margin-right:  3%;
                    }
                }
                .d-format{
                    padding-bottom: 1%;
                }

                .document-type{
                    margin:  0;
                    font-size: 18px;
                    color: #002D44;
                    font-family: publicaMedium;
                  }
                  .d-files{
                   margin:  0;
                   font-size: 16px;
                   line-height: 17px;
                   color: #909496;
                 }
                  .document-action{
                    text-align: end;
                    vertical-align: middle;
                  .eye{
                   display: inline-block;
                   text-align: center;
                     vertical-align: middle;
                     width: 40px;
                     height: 40px;
                     background-color: #F1F1F1;
                     border-radius: 8px;
                     margin-left: 8px;
                  }
                }
            }
        }
    }
}
.changebutton{
    height: 40px !important;
    // width:35px !important;
    background-color:'#ffffff' !important;
    color: "#03A8EE";
    border: '1px solid #03A8EE';
}

.smallscreenchange{
display: none;
}


@media screen and (max-width:768px){
    .changebutton{
        height: 30px !important;
        width:60px !important;
        background-color:'#ffffff' !important;
        color: "#03A8EE" !important;
        border: '1px solid #03A8EE' !important;
        padding: 5px;
    }
    .changebig{
        display: none !important;
        }
    .smallscreenchange{
        display: block;
        float: right;
        //  margin-top: -75px;
        }
.manager-head{
    th{
        font-size: 12px !important;
    }
}
.manager-body{
    font-size: 14px !important;
    .manager-data{
        .m-name{
            font-size: 20px !important;
        }
        .m-img{
            width: 45px !important;
            height: 45px !important;
        }
    }
   }

   .document-body{
       td{
           vertical-align: middle !important;
       }
   }
}

@media screen and (max-width:1024px){
    .changebutton{
        height: 33px !important;
        width:66px !important;
        background-color:'#ffffff' !important;
        color: "#03A8EE" !important;
        border: '1px solid #03A8EE' !important;
    }
    .changebig{
        display: none !important;
        }
    .smallscreenchange{
        display: block;
        float: right;
        //  margin-top: -85px;
        }
    }

    .animate-details{
        position: relative;
        -webkit-animation-name: mydetails;
        -webkit-animation-duration: 2s;
        animation-name: mydetails;
        animation-duration: 2s;
        // visibility: visible;
        //  opacity: 1;
        // transition: visibility 0s 3s, opacity 3s linear;
        // animation-timing-function: ease-out;
      }
    
      @-webkit-keyframes mydetails {
        0% {opacity: 0;transform: translateX(550px);}
        // 50% {left: -100px;opacity: 0.5; background: transparent;}
        // 70% {opacity: 0.7;transform: translateX(-100px)}
        100% {opacity: 1;transform: translateX(0px);}
      }
    
      @keyframes mydetails {
        0% {opacity: 0;transform: translateX(550px);}
        // 50% {left: -100px;opacity: 0.5; background: transparent;}
        // 70% {opacity: 0.7;transform: translateX(-100px)}
        100% {opacity: 1;transform: translateX(0px);}
      }


      .animate-detailsleave{
        position: relative;
        -webkit-animation-name: mydetailsleave;
        -webkit-animation-duration: 5s;
        animation-name: mydetailsleave;
        animation-duration: 5s;
        // visibility: hidden;
        //  opacity: 0;
        // transition: visibility 0s 3s, opacity 3s linear;
        // animation-timing-function: ease-out;
      }
    
      @-webkit-keyframes mydetailsleave {
        0% {opacity: 1;transform: translateX(0px);}
        // 50% {left: -100px;opacity: 0.5; background: transparent;}
        // 70% {opacity: 0.7;transform: translateX(-100px)}
        100% {opacity: 1;transform: translateX(1200px);}
      }
    
      @keyframes mydetailsleave {
        0% {opacity: 1;transform: translateX(0px);}
        // 50% {left: -100px;opacity: 0.5; background: transparent;}
        // 70% {opacity: 0.7;transform: translateX(-100px)}
        100% {opacity: 1;transform: translateX(1200px);}
      }