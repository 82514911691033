.floatingpopup {
  background: #03A8EE;
  position: fixed;
  left: 50%;
  width: 250px;
  height: 40px;
  border-radius: 18px;
  display: flex !important;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  padding-top: 3px;
  padding-right: 1vw;
  padding-left: 1vw;
}

.animate-float {
    -webkit-animation-name: mymovetop1;
    -webkit-animation-duration: 3s;
    animation-name: mymovetop1;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    visibility: visible;
    opacity: 1;
    transition: opacity 3s linear;
  }

  @-webkit-keyframes mymovetop1 {
 0% {
  opacity: 0;
  transform: translateY(-210px);
}
100% {
  opacity: 1;
  transform: translateY(-150px);
}
  }

  @keyframes mymovetop1 {
    0% {
      opacity: 0;
      transform: translateY(-210px);
    }
    100% {
      opacity: 1;
      transform: translateY(-150px);
    }
  }

  .floatrefresh {
    color: white;
    font-size: 22px;
    margin: 7px 9px 0px -6px;
    cursor: pointer;
    visibility: visible;
    transition: all 0.3s ease-in-out 0s;
}

@keyframes rotating1 {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .floatrefreshrotate{
    color: white;
    font-size: 22px;
    position: absolute;
    margin: 7px 198px 0px -3px;
    cursor: pointer;
    visibility: visible;
    -webkit-animation: rotating1 0.6s linear infinite;
    -moz-animation: rotating1 0.6s linear infinite;
    -ms-animation: rotating1 0.6s linear infinite;
    -o-animation: rotating1 0.6s linear infinite;
    animation: rotating1 0.6s linear infinite;
    transition: all 0.2s infinite ease-in-out 0.2s;
  }

.floatimage{
    width: 25px;
    height: 25px;
    margin-left: -5px;
    border-radius: 12px;
}

.floatText {
  color: white;
  margin: 6px -6px 0px 11px;
  font-family: ui-monospace;
  font-size: 18px;
  font-family: publicaBold;
}