.searchbar{
    border-bottom: 1px solid #979797;
    display: flex;
    i{
     color: #909496;
     align-self: center;
     font-size: 15px;
    }
       .search-name{
          height: 22px;
          width: 90%;
          border: none;
          font-size: 16px;
          color: #909496;
          font-family: publicaSans;
      }
      :focus{

          outline: #ffff;
       }
       ::placeholder{
           color: #909496;
           font-size: 16px;
           line-height: 19px;
           font-family: publicaSans;
        }
  }
  .searchbar-driver{
    width: 26.5% !important;
  }