.articles{
    height: 597px;
    box-sizing: border-box;
    margin: 4%  10% 6% 10%;
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;

    .article-title{
        color: #002D44;
        font-size: 48px;
        line-height: 52px;
        font-family: publicaBold;
        margin-bottom: 30px;
        margin-top: 37px;
    }
    .article-container{
        display: flex;
        margin-bottom: 3%;
        width: 95%;
    .article-card:hover{
        background-color: #F8F8F8;
        box-shadow: 0.6px 0.6px 14px rgba(0,0,0,0.09);
    }
    .article-card{
        height: 100%;
        padding: 1%;
        border-radius: 18px;
        border: 1px solid #F1F1F1;
        display: flex;
        flex-direction: column;
        margin-right: 1.5%;
        box-sizing: border-box;
    .card-title{
        width: 100%;
        font-size: 22px;
        font-family: publicaMedium;
        color: #002D44;
        margin-bottom: 0;
        box-sizing: border-box;
        margin-top: 12px;
    }
    .card-details{
        width: 100%;
        color: #909496;
        font-size: 16px;
        margin-bottom: 0;
        box-sizing: border-box;
    }
    .date{
        font-size: 18px;
        color: #909496;
        margin-top: 10px;
    }
    }
    }

    .more{
        color: #03A8EE;
        font-size: 20px;
        line-height: 24px;
        font-family: publicamedium;
        margin-bottom: 0;
    }
    .more-bottom-line{
        border: 1.5px solid #03A8EE;
        width: 53px;
    }
}

@media screen and (width:768px) and (height:1024px){

    .articles{
        height: 425px !important;
        margin-bottom: 1% !important;

        .article-container{
            margin-bottom: 0 !important;
        }

        .article-title{
            font-size: 30px !important;
            line-height: 32px !important;
        }
    }
    .article-card{
        width: 36% !important;
        height: 90% !important;
        padding: 1%;
        border-radius: 9px !important;
        border: 1px solid #F1F1F1;
        display: flex;
        flex-direction: column;
        margin-right: 1% !important;
        box-sizing: border-box;

        .art-img{
            width: 100%;
            height: 40%;
        }

        .card-title{
            font-size: 12px !important;
            box-sizing: border-box;
            margin: 0 !important;
            margin-top: 3% !important;
        }

        .card-details{
            font-size: 10px !important;
            margin-bottom: 0;
        }
        .date{
            font-size: 12px !important;
            color: #909496;
        }
    }
}