.successcontainer{
    height: 550px;
    width: 850px;
    border-radius: 25px;
    margin: auto;
    margin-top: 3%;
    background: linear-gradient(231.16deg, #E1F6FF 0%, #FFF0D9 100%);
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.28);
  }
  
  .mainheading {
    margin: auto;
    padding-top: 25px;
    padding-bottom: 40px;
    height: 74px;
    width: 578px;
    color: #002D44;
    font-family: PublicaSans;
    font-size: 39px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 37px;
    text-align: center;
    }
    .description {
    height: 84px;
    width: 703px;
    color: #909496;
    font-family: PublicaSans;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: -webkit-center;
    padding-top: 2.3vw;
    padding-bottom: 23px;
    margin: auto;
    }
    .email {
        height: 28px;
        width: 713px;
        color: #002D44;
        font-family: PublicaSans;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        margin: auto;
        padding-top: 4vw;
      }
      .s-name {
        height: 28px;
        width: 713px;
        color: #002D44;
        font-family: PublicaSans;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        padding-top: 35px;
        margin-bottom: 25px;
        margin: auto;
      }
  
   
    .socialicons{
        text-align: center;
    }
    .fb {
      height: 42px;
      width: 42px;
      margin-left: 20px;
    }
    .tw {
      height: 42px;
      width: 42px;
    }
    .have-a-question-con {
        height: 50px;
        width: 389px;
        color: #002D44;
        font-family: PublicaSans;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;
        margin: auto;
        margin-top: 40px;
      }
      .cardouter{
        background: white;
        padding: 25px;
        box-shadow: 0 2px 13px 0 rgba(0,0,0,0.28);
      }

      .success-header-section{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .just{
            width: 76px;
            color: #002D44;
            font-family: PublicaSans;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 37px;
            }
        .action{
            display: flex;
            height: 37px;
            width: 94px;
            color: #909496;
            font-family: PublicaSans;
            font-size: 23px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
        }
    }


    @media screen and (max-width:1024px){
      .successcontainer{
        height: 550px;
        width: 100%;
        border-radius: 25px;
        margin: auto;
        margin-top: 3%;
        background: linear-gradient(231.16deg, #E1F6FF 0%, #FFF0D9 100%);
        box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.28);
      }
    }
    @media screen and (max-width:768px) and (max-width:1024px){

      .successcontainer{
        height: 550px;
        width: 100%;
        border-radius: 25px;
        margin: auto;
        margin-top: 3%;
        background: linear-gradient(231.16deg, #E1F6FF 0%, #FFF0D9 100%);
        box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.28);
      }
      
      .mainheading {
        margin: auto;
        padding-top: 25px;
        padding-bottom: 40px;
        height: 74px;
        width: 100%;
        color: #002D44;
        font-family: PublicaSans;
        font-size: 39px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 37px;
        text-align: center;
        }
        .description {
        height: 84px;
        width: 100%;
        color: #909496;
        font-family: PublicaSans;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: -webkit-center;
        padding-top: 25px;
        padding-bottom: 23px;
        margin: auto;
        }
        .email {
            height: 28px;
            width: 100%;
            color: #002D44;
            font-family: PublicaSans;
            font-size: 24px;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
            margin: auto;
            padding-top: 60px;
          }
          .s-name {
            height: 28px;
            width: 100%;
            color: #002D44;
            font-family: PublicaSans;
            font-size: 24px;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
            padding-top: 35px;
            margin-bottom: 25px;
            margin: auto;
          }
      
       
        .socialicons{
            text-align: center;
        }
        .fb {
          height: 42px;
          width: 42px;
          margin-left: 20px;
        }
        .tw {
          height: 42px;
          width: 42px;
        }
        .have-a-question-con {
            height: 50px;
            width: 100%;
            color: #002D44;
            font-family: PublicaSans;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 25px;
            text-align: center;
            margin: auto;
            margin-top: 55px;
          }
    }
